<template>

      <er-carousel-index >
        </er-carousel-index>
</template>
<script>
   import ErCarouselIndex from '../components/CarouselIndex.vue';
   export default {
      name: 'app',
      data() {
            },
      components: {
         ErCarouselIndex//一定要进行组件声明，不然不能引用子组件
      },
    //   mounted() {
    //        if (this._isMobile()) {
    //             alert("手机端");
    //             console.log( this.$router,'~~~' )
    //             this.$router.replace('/m_index');
    //         } else {
    //             alert("pc端");
    //             this.$router.replace('/pc_index');
    //         }
    //   },
    //   methods: {
    //       _isMobile: function(){
    //         let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    //         return flag;
    //     }
    //   }
   }
</script>